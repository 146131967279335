<template>
  <div class="wrapper">
    <div class="btn-group">
      <div>
        <admin-title :title="$route.meta.name"></admin-title>
      </div>
      <div>
        <el-button type="primary" icon="el-icon-plus" size="small" @click="addRemark">添加备注</el-button>
        <el-button type="info" icon="el-icon-back" size="small" @click="$router.back()">返回</el-button>
      </div>
    </div>
    <a-divider />
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="page_size" />
    <div style="margin:30px 0"></div>
    <!-- <admin-title title="添加备注"></admin-title>
    <a-divider /> -->
    <!--  -->
    <el-dialog title="添加备注" :visible.sync="dialogVisible" width="width" :show-close="false">
      <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
        <el-form-item label="备注" prop="content">
          <el-input style="width:500px" type="textarea" v-model="formData.content" rows="4" maxlength="500" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="" @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveRemark">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getRemarkListAPI, addRemarkAPI } from './api'
const columns = [
  {
    label: '备注信息',
    prop: 'content',
    minWidth: '200',
    customRender(h, row) {
      return <p class="one-line" title={row['content']}> {row['content']} </p>
    }
  },
  {
    label: '操作人',
    prop: 'user',
    minWidth: '130',
    customRender(h, row) {
      return <p>{row['user']['userName']}</p>
    }
  },
  {
    label: '操作时间',
    prop: 'createTime',
    minWidth: '130',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: 'IP地址',
    prop: 'ip',
    minWidth: '120',
    customRender(h, row) {
      return (
        <p class="small-size" title={row['ip']}>
          {row['ip']}
        </p>
      )
    }
  }

  // {
  //   label: '操作',
  //   width: '100',
  //   fixed: 'right',
  //   customRender(h, row) {
  //     return (
  //       <div>
  //         <el-link
  //           underline={false}
  //           style="margin-right:20px"
  //           type="primary"
  //           onClick={() => {
  //             this.addRemark(row['content'])
  //           }}
  //         >
  //           添加备注
  //         </el-link>
  //       </div>
  //     )
  //   }
  // }
]
export default {
  name: 'OperationLog',

  data() {
    return {
      dialogVisible: false,
      columns,
      currentPage: 1,
      page_size: 10,
      total: 0,
      list: [],
      formData: {
        memberId: this.$route.params.memberId,
        content: ''
      },
      rules: {
        content: [
          {required: true, message: "备注内容不能为空", trigger: "blur"}
        ]
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getRemarkList()
  },
  watch: {
    currentPage() {
      this.getRemarkList()
    },
    page_size() {
      this.getRemarkList()
    }
  },
  activated() {
    this.getRemarkList()
    this.formData.memberId = this.$route.params.memberId
  },
  methods: {
    async getRemarkList() {
      let params = { page: this.currentPage, pageSize: this.page_size, memberId: this.$route.params.memberId }
      const res = await getRemarkListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    addRemark() {
      this.dialogVisible = true
      this.formData.content = ''
    },
    saveRemark() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          addRemarkAPI(this.formData).then(() => {
            this.$message.success('添加成功')
            this.dialogVisible = false
            this.getRemarkList()
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .btn-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
