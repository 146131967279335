import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: `/admin/member/page`,
    method: 'get',
    params
  })
}
// 备注列表
export function getRemarkListAPI(params) {
  return http({
    url:'/admin/member/remark/page',
    method:'get',
    params
  })
}
// 添加备注
export function addRemarkAPI(data){
  return http({
    url:'/admin/member/remark',
    method:'post',
    data
  })
}
// 客户经理列表
export function getAdminUserList(params) {
  return http({
    url: `/admin/user/list`,
    method: 'get',
    params
  })
}
